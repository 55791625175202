<template>
  <div class="shadow rounded-lg flex flex-col xs:w-full lg:w-1/3 max-w-xs">
    <div class="p-8 flex flex-row flex-grow items-center">
      <font-awesome-icon class="text-4xl mr-4" :icon="['fas', iconName]" />
      <div class="text-left">
        <p class="text-lg mb-0">{{ cardTitle }}</p>
        <p class="text-2xl font-bold">{{ cardSubtitle }}</p>
      </div>
    </div>
    <div class="bg-gray-100 px-5 py-2 self-stretch justify-self-end">
      <p class="text-md font-bold text-left">
        <a class="text-link" :href="createUrl()">{{ linkLabel }}</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      required: true
    },
    cardTitle: {
      type: String,
      required: true
    },
    cardSubtitle: {
      type: [Number, String],
      required: true
    },
    eventId: {
      type: String,
      required: true
    },
    urlBase: {
      type: String,
      required: true,
      validator: (url) => ['tickets', 'orders'].includes(url)
    },
    urlParams: {
      type: String,
      required: false
    },
    linkLabel: {
      type: String,
      required: true
    }
  },
  methods: {
    createUrl() {
      const url = `/${this.urlBase}?eventId=${this.eventId}`;

      if (this.urlParams) {
        return `${url}&${this.urlParams}`;
      }

      return url;
    }
  }
};
</script>
