<template>
  <div>
    <div class="flex flex-col w-full">
      <ProgressBar :progress="progress" variant="green" />
      <div class="flex flex-row justify-between" v-if="startTitle || startSubtitle || endTitle || endSubtitle">
        <div class="flex flex-col align-start text-left">
          <p>{{ startTitle }}</p>
          <p>{{ startSubtitle }}</p>
        </div>
        <div class="flex flex-col align-end text-right">
          <p>{{ endTitle }}</p>
          <p>{{ endSubtitle }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from '@/components/ui/ProgressBar';
export default {
  components: {
    ProgressBar
  },
  props: {
    progress: {
      type: Number,
      required: true
    },
    startTitle: {
      type: String,
      required: false
    },
    startSubtitle: {
      type: String,
      required: false
    },
    endTitle: {
      type: String,
      required: false
    },
    endSubtitle: {
      type: String,
      required: false
    }
  }
};
</script>
